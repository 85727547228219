.swatch-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.swatch-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.colorChip {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  gap: 1rem;
  font-family: var(--font-primaryFont);
  border: 1px solid var(--color-jokerWhite50-opacity15);
  border-radius: 0.3rem;
  box-shadow: 0px 1px 1.1px hsl(var(--shadow-color) / var(--opacity20)),
  0px 5px 5.4px -1.4px hsl(var(--shadow-color) / var(--opacity15)),
  -0.2px 20.3px 21.8px -2.8px hsl(var(--shadow-color) / var(--opacity15)), -1px 1px 0 hsla(0, 0%, 100%, 0.1) inset, -2px 2px 2px var(--color-jokerWhite50-opacity05) inset;
}

.opacity-selector-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
}

.opacity-selector-container label {
  display: flex;
  color: var(--color-jokerWhite50);
  font-size: 0.6rem;
  text-shadow: 0px 1px 3px var(--color-jokerBlack300-opacity80);
  position: relative;
  top: -1.7rem;
  left: 3rem;
  margin-left: -3rem;
}

.opacity-selector-container > select {
  width: min-content;
  padding: 0.5rem 0.5rem;
  appearance: unset;
  background: var(--color-jokerBlack300-opacity30);
  border: 2px dotted var(--color-jokerWhite50-opacity60);
  border-radius: var(--border-Radius200);
  box-shadow: var(--shadow-elevation-medium);
  transition: all 200ms ease;
  color: white;
}
.opacity-selector-container > select:hover {
  transform: scale(1.1);
  transition: all 200ms ease;
  background: var(--color-jokerBlack300-opacity60);
  border: 1px solid var(--color-jokerWhite50-opacity20);
  box-shadow: 0px 1px 1.1px hsl(var(--shadow-color) / var(--opacity20)),
    0px 5px 5.4px -1.4px hsl(var(--shadow-color) / var(--opacity15)),
    -0.2px 20.3px 21.8px -2.8px hsl(var(--shadow-color) / var(--opacity15));
}
.opacity-selector-container > select:focus {
  outline: 3px solid var(--color-jokerGreen400);
  animation: bounce 500ms infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}

.uncopySuccess {
  height: 2.5rem;
  width: 2.5rem;
  background: var(--color-jokerBlack300-opacity30);
  border: 1px solid var(--color-jokerWhite50-opacity30);
  border-radius: var(--border-Radius200);
  box-shadow: var(--shadow-elevation-medium);
  transition: all 200ms ease;
}
.uncopySuccess:hover {
  transform: scale(1.1);
  transition: all 200ms ease;
  background: var(--color-jokerBlack300-opacity60);
  border: 1px solid var(--color-jokerWhite50-opacity20);
  box-shadow: var(--shadow-elevation-high);
  transform: translateY(-2px);
}
.copy-icon {
  height: 20px;
  width: 20px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%);
  opacity: 0.5;
}
.copySuccess {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: var(--border-Radius200);
  box-shadow: var(--shadow-elevation-medium);
  transition: all 200ms ease;
  background-color: var(--color-jokerGreen400-opacity30);
  font-size: 0.8rem;
  border: 1px solid var(--color-jokerGreen300-opacity60);
  transform: translateY(-2px);
}






.copySuccess:hover {
  background-color: var(--color-jokerGreen400-opacity30);
  border: 1px solid var(--color-jokerGreen300-opacity60);
  box-shadow: var(--shadow-elevation-high);
}


/* tablet media query */
@media screen and (min-width: 500px) and (max-width: 768px) {
  .swatch-group{width:100%}
}

/*mobile */
@media screen and (max-width: 500px) {
  .swatch-group{width:100%}
}