.sidebar {

  background-color: var(--color-jokerBlack500);
  padding: 1.5rem 0 1.5rem 1.5rem; 
  box-shadow: 0.75rem 0 2rem var(--color-jokerBlack900-opacity30), 0px -2px 8px var(--color-jokerBlack900);
  border-right: 1px solid var(--color-jokerBlack200-opacity40);

}

.sidebar h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
}

.sidebar nav ul li {
  margin-bottom: 10px;
}

.sidebar nav ul li a {
  text-decoration: none;
  color: var(--color-jokerBlack100);
  font-size: var(--font-sizeParagraph01);
  display: block;
  padding: 0.5rem;
  transition: all 200ms ease-in-out;
}

.sidebar nav ul li a:hover {
  color: var(--color-jokerBlack50);
  padding: 0.5rem 1rem 0.5rem 1rem;
  transition: all 200ms ease-in-out;
  border-left: 8px solid var(--color-jokerGold400);;
}





 .sidebar-item {
  text-decoration: none;
  color: var(--color-jokerBlack100);
  font-size: var(--font-sizeParagraph01);
  display: block;
  padding: 0.5rem;
  transition: all 200ms ease-in-out;
  border-radius: var(--border-Radius300);
  margin-bottom: 20px;
}
 .sidebar-item:hover {
  color: var(--color-jokerWhite50);
  padding: 0.5rem 1rem 0.5rem 1rem;
  transition: all 200ms ease-in-out;
  border-radius: var(--border-Radius200) 0 0 var(--border-Radius200);
  font-size: var(--font-sizeParagraph01);
  
 

 }
 .sidebar-item.selected {
  background: linear-gradient(45deg, var(--color-jokerGold400-opacity15), var(--color-jokerGold400-opacity00)) ;
  font-size: var(--font-sizeParagraph01);
  border-radius: var(--border-Radius200) 0 0 var(--border-Radius200);
  transition: all 200ms ease-in-out;
  color: var(--color-jokerWhite50);
  padding-left:1rem;
  padding-top:1rem;
  padding-bottom:1rem;
  box-shadow: var(--shadow-elevation-medium);
  border-left: 5px solid var(--color-jokerGold400-opacity60);
  border-top:1px solid var(--color-jokerGold400-opacity20);
  border-bottom: 1px solid var(--color-jokerGold400-opacity20);
  margin-left: 0.5rem;
  
 }


 .inner-sidebar-mobile select {
  background-color: var(--color-jokerBlack200-opacity40);
  width: 93%;
  height: 3rem;
  appearance: unset;
  text-decoration: none;
  color: var(--color-jokerWhite50);
  font-size: var(--font-sizeParagraph01);
  display: block;
  padding: 0.5rem;
  transition: all 200ms ease-in-out;
  border: 1px solid var(--color-jokerBlack100-opacity50);
  box-shadow: var(--shadow-elevation-high);
}


 