.whiteText {
  color: var(--color-jokerWhite50);
}
.codeholder {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--color-jokerBlack50-opacity10);
  border-radius: 1rem;
  background-color: var(--color-jokerBlack300);
  padding: 2rem;
  color: var(--color-jokerWhite50);
}

.style-code-name {
  font-weight: 700;
  color: var(--color-jokerWhite50);
}

.code-example {
  font-size: var(--font-sizeParagraph03);
  color: var(--color-jokerBlack100);
  line-height: 170%;
}
.code .copyTextSuccess {
  color: var(--color-jokerGreen100);
  text-shadow: 0px 0px 8px var(--color-jokerGreen200);
  transform: translateY(-3px);
  transition: all 200ms ease-in-out;
}
.code {
  font-family: "Courier New", Courier, monospace;
  font-size: var(--font-sizeParagraph03);
  border: 1px solid var(--color-jokerBlack50-opacity20);
  box-shadow: var(--shadow-elevation-medium);
  background: var(--color-jokerBlack500);
  border-radius: 0 var(--border-Radius400);
  padding: 0.3rem 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  text-wrap: wrap;
  transition: all 100ms ease-in-out;
}


.copyButton {
  height: 2.5rem;
  width: 2.5rem;
  background: var(--color-jokerBlack300);
  border: 1px solid var(--color-jokerBlack100-opacity30);
  border-radius: var(--border-Radius200);
  box-shadow: var(--shadow-elevation-medium);
  transition: all 200ms ease;
}
.copyButton:hover {
  transform: scale(1.1);
  transition: all 200ms ease;
  background: var(--color-jokerBlack300);
  border: 1px solid var(--color-jokerBlack100-opacity80);
  box-shadow: var(--shadow-elevation-high);
  transform: translateY(-2px);
}

.copySuccess {
  background-color: var(--color-jokerGreen400-opacity30);
  font-size: 0.8rem;
  border: 1px solid var(--color-jokerGreen300-opacity60);
}
.copySuccess:hover {
  background-color: var(--color-jokerGreen400-opacity30);
  border: 1px solid var(--color-jokerGreen300-opacity60);
}


@media screen and (max-width: 480px) {

  .codeholder {
    padding: 1rem;
    gap: 0.2rem;
  }


}
