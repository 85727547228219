.app {
  display: grid;
  grid-template-columns: minmax(25rem, auto) 1fr;
  height: 100dvh;
  background-color: var(--color-jokerBlack300);
}
/* Custom scrollbar styles */
.main-content::-webkit-scrollbar {
  width: 16px;
  
}

.main-content::-webkit-scrollbar-track {
  background: var(--color-jokerBlack200-opacity40);
  border-radius: 10px;
  border: 1px solid var(--color-jokerBlack50-opacity10);
  margin-top:10px;
  margin-bottom:10px;
  
  
}

.main-content::-webkit-scrollbar-thumb {
  background-color: var(--color-jokerBlack100-opacity80);
  border-radius: 10px;
  border: 1px solid var(--color-jokerBlack50-opacity30);
  box-shadow: var(--shadow-elevation-low);
}



.inner-sidebar-mobile {
  display: none;
}

.main-content {
  overflow-y: auto;
  padding: 3rem 4rem 10rem 4rem;
}
.page-title {
  font-size: var(--font-sizeHeading01);
}
.intro-paragraph {
  font-size: var(--font-sizeParagraph01);
  color: var(--color-jokerBlack50-opacity75);
  line-height: 170%;
  max-width: 90ch;
}
.intro-divider {
  border: 2px solid var(--color-jokerBlack800);
  margin: 3rem 0 6rem 0;
  box-shadow: 0 1px var(--color-jokerBlack50-opacity20);
}
.inner-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.badge {
  display: flex;
  width: fit-content;
  padding: 0.25rem 1rem;
  border-radius: var(--border-Radius400);
  align-self: flex-end;
}
.global-class {
  background-color: var(--color-jokerAlert400-opacity20);
  color: var(--color-jokerAlert400);
  border: 1px solid var(--color-jokerAlert400-opacity30);
  box-shadow: var(--shadow-elevation-low);
}

/* tablet media query */
@media screen and (min-width: 480px) and (max-width: 1024px) {
  .app {
    grid-template-columns: minmax(12rem, auto) 1fr;
    height: 100vh;
  }
  .main-content {
    padding: 2rem 3rem;
  }
}

/*mobile */
@media screen and (max-width: 480px) {
  .app {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 6fr;
  }

  .sidebar {

    background-color: var(--color-jokerBlack500);
    padding: 1.5rem 0 0.5rem 1.5rem; 
    box-shadow: 0.75rem 0 2rem var(--color-jokerBlack900-opacity30), 0px -2px 8px var(--color-jokerBlack900);
    border-bottom: 1px solid var(--color-jokerBlack200);
   
  
  }
  .main-content {
    padding: 1rem 1.5rem 6rem 1.5rem;
  }

  .inner-sidebar-mobile {
    display: block;
  }

 
  .inner-sidebar-desktop {
    display: none;
  }
}
